import { useState } from "react";
import { useLocation } from "react-router-dom";
import { NJSocialHandlesModal } from "../../common/component/NJSocialHandlesModal";
import isoCertificate from "../../assets/images/isoCertificate.gif";

const HomeFooter = () => {
  const [showSocialModal, setShowSocialModal] = useState(false);
  const handleSocialMediaPopup = () => {
    setShowSocialModal(!showSocialModal);
  }
  return (
    <>
      {showSocialModal && <NJSocialHandlesModal isShowNJSocialHandlesModal={showSocialModal}
        setShowSocialModal={setShowSocialModal}

      />}
      <div className="home-footer">
        <div className="container">

          <div className="seperator"></div>

          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12">
              <h1 className="heading-text">NJ CAPITAL PRIVATE LIMITED</h1>
              <h3 className="sub-heading-text">
                <strong>Registered Office Address</strong>
              </h3>
              <p>
                Block No 501, 2nd Floor, C Tower, Udhna Udyognagar Sangh
                Commercial Complex, Central Road No. 10, Udhna, Surat - 394210
              </p>
              <h3 className="sub-heading-text">
                <strong> Corporate Office Address</strong>
              </h3>
              <p>
                Office No. 16A, 16B, 16C and 17, 5th Floor, Techniplex-I Complex,
                Veer Savarkar Flyover, Goregaon, West Mumbai-400064
              </p>
              <p className="mt-4 mb-4">
                <strong>CIN</strong> : U67120GJ2009PTC057351 |{" "}
                <strong>RBI CoR( Certificate of Registration no)</strong> :&nbsp; 

                {/* WFMS Request :- 74583 : start */}

                {/* B.01.06.00606 */}

                B.01.00606

                {/* WFMS Request :- 74583 : end */}
              </p>
              <p className="mt-4 mb-4 footer-links">
                <a href="/partner/policies-code">Policies & Codes </a> |{" "}
                <a href="/partner/other-compliances">Other compliances</a> |{" "}
                <a href="/partner/corporate-governance">Corporate Governance</a> |{" "}
                <a href="/partner/terms-of-use">Terms of use</a> |{" "}
                <a href="/partner/contact">Contact us</a> |{" "}
                <a
                  href="https://www.njgroup.in/career/careers.fin?cmdAction=loadCurrentOpenings"
                  target="_blank" rel="noreferrer"
                >
                  Careers
                </a>{" "}
                | <a href="/partner/faq">FAQ'S </a> |{" "}
                <a href="https://sachet.rbi.org.in/" target="_blank" rel="noreferrer">
                  Sachet Portal{" "}
                </a>{" "}
                |{" "}
                <a href="/partner/list-lsps/list-of-lsps">
                  Our Partners
                </a>{" "}
                | <a href="#" onClick={handleSocialMediaPopup}>NJ Capital Social Media Handles</a>{" "}
                | <a href="/partner/grivence">Grievance Redressal</a>{" "}
                | <a href="/partner/disclaimer">Disclaimer</a>
              </p>
              <p className="mt-4">
                For complaints/grievances -{" "}
                <a href="/partner/grivence" className="blue">
                  Click Here
                </a>
              </p>
              {/* <p className="text-center mb-0 mt-2">
                <strong>E Wealth Customers : </strong>
                <a href="tel:0261-4025000" className="blue">
                  0261-4025000
                </a>
                &nbsp;or&nbsp;
                <a href="tel:0261-6155000" className="blue">
                  0261-6155000
                </a>
              </p>
              <p className="text-center mb-0">
                <strong>Non E Wealth Customers : </strong>
                <a href="tel:0261-6913333" className="blue">
                  0261-6913333
                </a>
              </p> */}
            </div>
          </div>
          <div>
            <br />
            <a
              href={require("../../assets/download/IsoCertificate.pdf")}
              className="m-0 pb-0 align-self-center blue"
              target="_blank"
              rel="noreferrer"
            >
              <img height="70" src={isoCertificate} alt="ISO Certificate" />
            </a>
          </div>
        </div>
      </div>
      <div className="bottom-footer text-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <h6 className="copyRight">
                Copyright © 2023 NJ Capital Private Limited. All Rights Reserve
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeFooter;