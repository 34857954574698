import { lazy, Suspense, useEffect, useMemo } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { customerRoutes, onboardRoutes, partnerRoutes } from "./index";
import { getLocalStorage, decodeString } from "../common/utilities";
import PrivateRoute from "./PrivateRoute";
import { Spinner } from "../common/Spinner";
import { UseIdle } from "../utils/useIdleTimer";
import { useTaxStatus } from "../providers/TaxStatusProvider";
import { CheckAuthUrl } from "../common/constants";
import {
  PromptModal,
  SessionClosedModal,
} from "../common/component/CommonModal";
import CorporateGovernance from "../screens/home-page/corporateGovernance/CorporateGovernance";
import TermLoan from "../screens/home-page/business-verticals/TermLoan";

//lazy compoennt loading and refresh page manage
const lazyWithRetry = (componentImport: () => Promise<any>) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem("refreshed") || "false"
    );
    try {
      const component = await componentImport();
      window.localStorage.setItem("refreshed", "false");
      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem("refreshed", "true");
        return window.location.reload();
      }
      throw error;
    }
  });

//define of paths of components
const DraftLogin = lazyWithRetry(() => import("../draft-auth/DraftLogin"));
const PartnerLogin = lazyWithRetry(
  () => import("../partner-auth/PartnerLogin")
);
const HelpUs = lazyWithRetry(() => import("../screens/Onboard/HelpUs"));
const ViewEnroll = lazyWithRetry(
  () => import("../screens/EnrollmentForm/EnrollmentView")
);
const Homepage = lazyWithRetry(() => import("../screens/home-page/Homepage"));
const Disclaimer = lazyWithRetry(
  () => import("../screens/home-page/disclaimer/Disclaimer")
);
const CustomerLogin = lazyWithRetry(
  () => import("../customer-auth/CustomerLogin")
);
const AboutPage = lazyWithRetry(() => import("../screens/home-page/about"));
const Contact = lazyWithRetry(
  () => import("../screens/home-page/contact/Contact")
);
const PoliciesCode = lazyWithRetry(
  () => import("../screens/home-page/policies-code/PoliciesCode")
);
const PrivicyPolicy = lazyWithRetry(
  () => import("../screens/home-page/policies-code/PrivicyPolicy")
);
const OtherCompliances = lazyWithRetry(
  () => import("../screens/home-page/other-compliances/OtherCompliances")
);
const TermsOfUse = lazyWithRetry(
  () => import("../screens/home-page/terms-use/TermsOfUse")
);
const Faq = lazyWithRetry(() => import("../screens/home-page/faq/Faq"));
const DematSecurities = lazyWithRetry(
  () => import("../screens/home-page/business-verticals/DematSecurities")
);
const NonDematSecurities = lazyWithRetry(
  () => import("../screens/home-page/business-verticals/NonDematSecurities")
);
const BajajFinance = lazyWithRetry(
  () => import("../screens/home-page/business-verticals/BajajFinance")
);
const ConsumerLoan = lazyWithRetry(
  () => import("../screens/home-page/business-verticals/ConsumerLoan")
);
const TataCapital = lazyWithRetry(
  () => import("../screens/home-page/business-verticals/TataCapital")
);
const MoratoriumPolicy = lazyWithRetry(
  () => import("../screens/home-page/poratorium-policy/MoratoriumPolicy")
);
const ResolutionFramework = lazyWithRetry(
  () => import("../screens/home-page/resolution-framework/ResolutionFramework")
);
const Grivence = lazyWithRetry(
  () => import("../screens/home-page/grivence/Grivence")
);
const LspsList = lazyWithRetry(
  () => import("../screens/home-page/customer-services/LspsList")
);
const FreshCustomerLogin = lazyWithRetry(
  () => import("../screens/customer/FreshCustomerLogin")
);
const PartnerChangePass = lazyWithRetry(
  () => import("../partner-auth/PartnerChangePass")
);
const PartnerForgotPass = lazyWithRetry(
  () => import("../partner-auth/PartnerForgotPass")
);
const LoanRequest = lazyWithRetry(
  () => import("../screens/customer/CheckUpiStatus")
);
const NotFound = lazyWithRetry(() => import("../common/notFoundPage/NotFound"));
const BusinessCalcEstimate = lazyWithRetry(
  () => import("../screens/business/business-calc/BusinessCalcIndex")
);
const ChangeMandate = lazyWithRetry(
  () => import("../screens/changeMandate/ChangeMandate")
);
const CustomerOTP = lazyWithRetry(
  () => import("../screens/customer/CustomerOTP")
);
const EsignService = lazyWithRetry(
  () => import("../screens/esign-service/EsignService")
);
const PhotoVerifyService = lazyWithRetry(
  () =>
    import("../screens/customer/stepper-tabs/Kyc-screens/PhotoVerifyService")
);
const MandateService = lazyWithRetry(
  () => import("../screens/customer/stepper-tabs/submit-mandate/MandateService")
);

//Changes for mobile app pages start
const Disclaimer_Mobile = lazyWithRetry(
  () => import("../screens/home-page/MobileAppPages/Disclaimer_mobile")
);

const Faq_Mobile = lazyWithRetry(
  () => import("../screens/home-page/MobileAppPages/Faq_mobile")
);

const LspsList_Mobile = lazyWithRetry(
  () => import("../screens/home-page/MobileAppPages/LspsList_mobile")
);

const Grivence_Mobile = lazyWithRetry(
  () => import("../screens/home-page/MobileAppPages/Grivence_mobile")
);

const PrivicyPolicy_Mobile = lazyWithRetry(
  () => import("../screens/home-page/MobileAppPages/PrivicyPolicy_mobile")
);

const TermsOfUse_Mobile = lazyWithRetry(
  () => import("../screens/home-page/MobileAppPages/TermsOfUse_mobile")
);
//Changes for mobile app pages end

const AppRoutes = () => {
  const { showSessionClosed } = useTaxStatus();
  const navigate = useNavigate();
  const location = useLocation();
  const screenIdeal = UseIdle();

  const timeStatus = useMemo(() => screenIdeal?.timeStatus, [screenIdeal]);
  const promptModal = useMemo(() => screenIdeal.timeOutModal, [screenIdeal]);
  const module = getLocalStorage("module");
  const decode = module && decodeString(module);

  useEffect(() => {
    //login page open according module
    if (timeStatus === "TIME_OUT") {
      if (decode === "Partner_Enrolment") {
        navigate("/partner/");
      }
      if (decode === "Customer") {
        navigate("/partner/customer/login");
      }
      if (decode === "Partner") {
        navigate("/partner/partner/login/");
      }
      if (decode === undefined || decode === "" || decode === null) {
        navigate("/home");
      }
    }
  }, [timeStatus]);

  //define routes path and components
  return (
    <>
      <Suspense fallback={<Spinner />}>
        {promptModal &&
          !CheckAuthUrl.includes(location.pathname) &&
          screenIdeal && <PromptModal screenIdeal={screenIdeal} />}
        {showSessionClosed && <SessionClosedModal />}
        <Routes>
          <Route path="/partner" element={<HelpUs />} />
          <Route path="/" element={<Homepage />} />
          <Route path="/partner/partner/draft/login" element={<DraftLogin />} />
          <Route path="/:section" element={<Homepage />} />
          <Route path="/partner/contact" element={<Contact />} />
          <Route path="/partner/disclaimer" element={<Disclaimer />} />
          <Route path="/partner/partner/login" element={<PartnerLogin />} />
          <Route path="/partner/customer/login" element={<CustomerLogin />} />
          <Route
            path="/partner/customer/check-upi-status"
            element={<LoanRequest />}
          />
          <Route
            path="/partner/partner/forgot-password"
            element={<PartnerForgotPass />}
          />
          <Route
            path="/partner/partner/change-password"
            element={<PartnerChangePass />}
          />
          <Route
            path="/partner/partner/business-calc-estimated"
            element={<BusinessCalcEstimate />}
          />
          <Route path="/partner/process-done" element={<ViewEnroll />} />
          <Route
            path="/partner/customer/link-login"
            element={<FreshCustomerLogin />}
          />
          <Route
            path="/partner/customer/change-mandate"
            element={<ChangeMandate />}
          />
          <Route
            path="/partner/customer/customer-otp"
            element={<CustomerOTP />}
          />
          <Route
            path="/partner/onboard/esign-service"
            element={<EsignService />}
          />
          <Route
            path="/partner/customer/photo-verify-service"
            element={<PhotoVerifyService />}
          />
          <Route
            path="/partner/customer/mandate-service"
            element={<MandateService />}
          />
          <Route path="404" element={<NotFound />} />
          {/* private routes */}
          {onboardRoutes &&
            onboardRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <PrivateRoute flow="onboard">
                    <route.element />
                  </PrivateRoute>
                }
              />
            ))}
          {partnerRoutes &&
            partnerRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <PrivateRoute flow="partner">
                    <route.element />
                  </PrivateRoute>
                }
              />
            ))}
          {customerRoutes &&
            customerRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <PrivateRoute flow="customer">
                    <route.element />
                  </PrivateRoute>
                }
              />
            ))}
          <Route path="/partner/policies-code" element={<PoliciesCode />} />
          <Route path="/partner/privicy-policy" element={<PrivicyPolicy />} />
          <Route
            path="/partner/moratorium-policy"
            element={<MoratoriumPolicy />}
          />
          <Route
            path="/partner/resolution-framework"
            element={<ResolutionFramework />}
          />
          <Route
            path="/partner/other-compliances"
            element={<OtherCompliances />}
          />
          <Route
            path="/partner/corporate-governance"
            element={<CorporateGovernance />}
          />
          <Route path="/partner/terms-of-use" element={<TermsOfUse />} />
          <Route path="/partner/faq" element={<Faq />} />
          {/* <Route
            path="/partner/demat-securities"
            element={<DematSecurities />}
          />
          <Route
            path="/partner/non-demat-securities"
            element={<NonDematSecurities />}
          /> */}
          <Route
            path="/partner/term-loan"
            element={<TermLoan />}
          />
          <Route path="/partner/bajaj-finance" element={<BajajFinance />} />
          <Route path="/partner/consumer-loan" element={<ConsumerLoan />} />
          <Route path="/partner/tata-capital" element={<TataCapital />} />
          <Route path="/partner/list-lsps" element={<LspsList />} />
          <Route path="/partner/grivence" element={<Grivence />} />
          <Route path="/partner/about" element={<AboutPage />} />
          <Route path="/partner/about/:section" element={<AboutPage />} />
          <Route path="/partner/list-lsps/:section" element={<LspsList />} />
          <Route path="*" element={<NotFound />} />

          {/* Routes for mobile app start*/}
          <Route path="/partner/disclaimer_mobile" element={<Disclaimer_Mobile />} />
          <Route path="/partner/list-lsps_mobile" element={<LspsList_Mobile />} />
          <Route path="/partner/grivence_mobile" element={<Grivence_Mobile />} />
          <Route path="/partner/privicy-policy_mobile" element={<PrivicyPolicy_Mobile />} />
          <Route path="/partner/terms-of-use_mobile" element={<TermsOfUse_Mobile />} />
          <Route path="/partner/faq_mobile" element={<Faq_Mobile />} />
          {/* Routes for mobile app end*/}
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;